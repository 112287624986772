import { ManageSubscriptionStepId } from 'types/manageSubscriptionTypes'
import { ONBOARDING_STEPS } from 'types/onboardingTypes'

export enum RouteNames {
  // onboarding flow routes
  HOME = '/',
  LOADING = '/onboarding/loading',
  ONBOARDING = '/onboarding',
  PAYMENT = '/payment',
  PAYMENT_SUCCESS = '/payment_success',
  UPDATE_SUBSCRIPTION = '/update_subscription',
  UPGRADE_ACCOUNT = '/upgrade_account',
  DOWNLOAD = '/download',

  // login flow routes
  LOGIN = '/login',
  LOGIN_CREATE_ACCOUNT = '/login/create_account',
  LOGIN_TOKEN = '/token_login',
  LOGIN_PAYMENT = '/login/payment',
  LOGIN_PAYMENT_SUCCESS = '/login/payment-success',
  LOGIN_UPDATE_SUBSCRIPTION = '/login/update_subscription',
  LOGIN_UPGRADE_ACCOUNT = '/login/upgrade_account', // Users still can see the upgrade account screen for futoken logins
  LOGIN_DOWNLOAD = '/login/download',

  MANAGE_SUBSCRIPTION = '/manage_subscription',
  MANAGE_SUBSCRIPTION_PAYMENT = '/manage_subscription/payment',
  MANAGE_SUBSCRIPTION_PAYMENT_SUCCESS = '/manage_subscription/payment-success',
  MANAGE_SUBSCRIPTION_UPDATE_SUBSCRIPTION = '/manage_subscription/update_subscription',
  MANAGE_SUBSCRIPTION_DOWNLOAD = '/manage_subscription/download',

  WELLHUB = '/wellhub',
  WELLHUB_LOGIN = '/wellhub/login',
  WELLHUB_REGISTER = '/wellhub/register',
  WELLHUB_WELCOME_BACK = '/wellhub/welcome-back',
  WELLHUB_DOWNLOAD = '/wellhub/download',

  // privacy policy
  PRIVACY = '/privacy',
  PRIVACY_APP = '/privacy-app',
  COOKIE_POLICY = '/cookie-policy',
  TERMS_OF_USE = '/terms'
}

export const STATIC_ROUTE_NAMES: string[] = [
  RouteNames.PRIVACY,
  RouteNames.PRIVACY_APP,
  RouteNames.COOKIE_POLICY,
  RouteNames.TERMS_OF_USE
]

export const AUTH_REQUIRING_ROUTES = [
  RouteNames.PAYMENT,
  RouteNames.UPDATE_SUBSCRIPTION,
  RouteNames.UPGRADE_ACCOUNT,
  RouteNames.DOWNLOAD,
  RouteNames.LOGIN_PAYMENT,
  RouteNames.LOGIN_UPDATE_SUBSCRIPTION,
  RouteNames.LOGIN_UPGRADE_ACCOUNT,
  RouteNames.LOGIN_DOWNLOAD
]

export const STRIPE_PRICES_REQUIRING_ROUTES = [
  RouteNames.PAYMENT,
  RouteNames.LOGIN_PAYMENT,
]
export const ONBOARDING_ROUTES_BY_STEP_ID: Record<ONBOARDING_STEPS, string> = {
  gender: '/onboarding/gender',
  age: '/onboarding/age',
  bad_habits: '/onboarding/bad_habits',
  bmi: '/onboarding/bmi',
  breakfast_time: '/onboarding/breakfast_time',
  current_body_type: '/onboarding/current_body_type',
  desired_body_type: '/onboarding/desired_body_type',
  dinner_time: '/onboarding/dinner_time',
  email: '/onboarding/email',
  existing_email_login: '/onboarding/existing_email_login',
  fasting_feelings: '/onboarding/fasting_feelings',
  fasting_knowledge: '/onboarding/fasting_knowledge',
  fasting_motivation: '/onboarding/fasting_motivation',
  goals: '/onboarding/goals',
  height: '/onboarding/height',
  home_or_outside: '/onboarding/home_or_outside',
  last_happy_weight: '/onboarding/last_happy_weight',
  lunch_time: '/onboarding/lunch_time',
  out_of_breath: '/onboarding/out_of_breath',
  perfect_weight: '/onboarding/perfect_weight',
  plan_is_ready: '/onboarding/plan_is_ready',
  progress: '/onboarding/progress',
  receive_email: '/onboarding/receive_email',
  reinforcement_before_after: '/onboarding/reinforcement_before_after',
  reinforcement_scientific: '/onboarding/reinforcement_scientific',
  sleep_quality: '/onboarding/sleep_quality',
  special_occasion: '/onboarding/special_occasion',
  special_occasion_date: '/onboarding/special_occasion_date',
  target_zones: '/onboarding/target_zones',
  the_one_plan: '/onboarding/the_one_plan',
  tracked_hours: '/onboarding/tracked_hours',
  walking_time: '/onboarding/walking_time',
  water_intake: '/onboarding/water_intake',
  weight: '/onboarding/weight',
  work_activity_level: '/onboarding/work_activity_level',
  workload_frequency: '/onboarding/workload_frequency',
  age_range: '/onboarding/age_range'
}

export const MANAGE_SUBSCRIPTION_STEPS_BY_ID: Record<ManageSubscriptionStepId, string> = {
  [ManageSubscriptionStepId.Login]: `${RouteNames.MANAGE_SUBSCRIPTION}/${ManageSubscriptionStepId.Login}`,
  [ManageSubscriptionStepId.Intro]: `${RouteNames.MANAGE_SUBSCRIPTION}`,
  [ManageSubscriptionStepId.SubscriptionStatus]: `${RouteNames.MANAGE_SUBSCRIPTION}/${ManageSubscriptionStepId.SubscriptionStatus}`,
  [ManageSubscriptionStepId.HaveQuestions]: `${RouteNames.MANAGE_SUBSCRIPTION}/${ManageSubscriptionStepId.HaveQuestions}`,
  [ManageSubscriptionStepId.CancellationReason]: `${RouteNames.MANAGE_SUBSCRIPTION}/${ManageSubscriptionStepId.CancellationReason}`,
  [ManageSubscriptionStepId.PauseSubscription]: `${RouteNames.MANAGE_SUBSCRIPTION}/${ManageSubscriptionStepId.PauseSubscription}`,
  [ManageSubscriptionStepId.PauseOffer]: `${RouteNames.MANAGE_SUBSCRIPTION}/${ManageSubscriptionStepId.PauseOffer}`,
  [ManageSubscriptionStepId.UnpauseSubscriptionSuccess]: `${RouteNames.MANAGE_SUBSCRIPTION}/${ManageSubscriptionStepId.UnpauseSubscriptionSuccess}`,
  [ManageSubscriptionStepId.PauseSubscriptionSuccess]: `${RouteNames.MANAGE_SUBSCRIPTION}/${ManageSubscriptionStepId.PauseSubscriptionSuccess}`,
  [ManageSubscriptionStepId.CancellationReasonExpensive]: `${RouteNames.MANAGE_SUBSCRIPTION}/${ManageSubscriptionStepId.CancellationReasonExpensive}`,
  [ManageSubscriptionStepId.CancellationReasonUndecided]: `${RouteNames.MANAGE_SUBSCRIPTION}/${ManageSubscriptionStepId.CancellationReasonUndecided}`,
  [ManageSubscriptionStepId.CancellationReasonAppComplicated]: `${RouteNames.MANAGE_SUBSCRIPTION}/${ManageSubscriptionStepId.CancellationReasonAppComplicated}`,
  [ManageSubscriptionStepId.CancellationReasonAppNotHelping]: `${RouteNames.MANAGE_SUBSCRIPTION}/${ManageSubscriptionStepId.CancellationReasonAppNotHelping}`,
  [ManageSubscriptionStepId.CancellationReasonGoalAchieved]: `${RouteNames.MANAGE_SUBSCRIPTION}/${ManageSubscriptionStepId.CancellationReasonGoalAchieved}`,
  [ManageSubscriptionStepId.CancellationReasonFoundBetterAlternative]: `${RouteNames.MANAGE_SUBSCRIPTION}/${ManageSubscriptionStepId.CancellationReasonFoundBetterAlternative}`,
  [ManageSubscriptionStepId.ContactUsAboutExperience]: `${RouteNames.MANAGE_SUBSCRIPTION}/${ManageSubscriptionStepId.ContactUsAboutExperience}`,
  [ManageSubscriptionStepId.SpecialOffer]: `${RouteNames.MANAGE_SUBSCRIPTION}/${ManageSubscriptionStepId.SpecialOffer}`,
  [ManageSubscriptionStepId.SpecialOfferAccepted]: `${RouteNames.MANAGE_SUBSCRIPTION}/${ManageSubscriptionStepId.SpecialOfferAccepted}`,
  [ManageSubscriptionStepId.CancelSubscriptionSuccess]: `${RouteNames.MANAGE_SUBSCRIPTION}/${ManageSubscriptionStepId.CancelSubscriptionSuccess}`,
  [ManageSubscriptionStepId.SubscriptionRestorationSuccess]: `${RouteNames.MANAGE_SUBSCRIPTION}/${ManageSubscriptionStepId.SubscriptionRestorationSuccess}`
}
