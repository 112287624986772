import { onboarding } from 'Containers/steps/Consts'
import { getUserLanguage, isValidEmail, validateEmail } from 'Lib'
import { mapPrivacyPolicyLink } from 'Lib/LinkUtils'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaEnvelope } from 'react-icons/fa'
import styled from 'styled-components'
import { images } from '../images'
import { useFeatureValue } from '@growthbook/growthbook-react'
import IconInput from 'Components/IconInput'

const EmailStepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Error = styled.span`
  color: red;
  margin-left: ${({ theme }) => theme.spacing.small};
  font-size: ${({ theme }) => theme.fontSizes.small};
  align-self: flex-start;
`

const DisclaimerOld = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: ${({ theme }) => theme.spacing.medium};
  gap: ${({ theme }) => theme.spacing.small};

  img {
    height: ${({ theme }) => theme.fontSizes.medium};
  }

  p {
    margin: 0;
    font-size: ${({ theme }) => theme.fontSizes.small};
  }
`

const Emoji = styled.div`
  font-size: 30px;
`

const Disclaimer1 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: ${({ theme }) => theme.spacing.medium};
  padding-bottom: 0;
  gap: 12px;

  p {
    margin: 0;
    font-size: ${({ theme }) => theme.fontSizes.small};
  }
  }`

const Disclaimer2 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: ${({ theme }) => theme.spacing.medium};
  padding-top: ${({ theme }) => theme.spacing.small};
  gap: 10px;

  p {
    margin: 0;
    font-size: ${({ theme }) => theme.fontSizes.small};
  }
}`

const PrivacyLink = styled.a`
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`

interface EmailStepProps {
  initialEmail?: string
  onEmailChanged: (email: string) => void
  onSubmit: () => void
}

const EmailStep: React.FC<EmailStepProps> = ({ onEmailChanged, onSubmit, initialEmail }) => {
  const newsletterDisclaimerVersion = useFeatureValue('newsletter-disclaimer', 'v1')

  const [email, setEmail] = useState(initialEmail?.trim() ?? '')
  const [emailBlurred, setEmailBlurred] = useState(false)
  const [emailError, setEmailError] = useState<string | undefined>(undefined)

  const { t } = useTranslation(onboarding)
  const lang = getUserLanguage()
  const privacyLink = mapPrivacyPolicyLink(lang)

  const handleOnSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    onSubmit()
  }

  const handleOnEmailChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailBlurred(false)

    const value = e.target.value.trim()
    onEmailChanged(value)
    setEmail(value)

    const emailError = validateEmail(value)
    setEmailError(emailError ?? '')
  }

  let title: string
  let subTitle: string

  switch (newsletterDisclaimerVersion) {
    case 'v4': {
      title = t('email-v4.title')
      subTitle = t('email-v4.subtitle')
      break
    }
    case 'v5': {
      title = t('email-v5.title')
      subTitle = t('email-v5.subtitle')
      break
    }
    default: {
      title = t('email.title')
      subTitle = ''
    }
  }

  const shouldShowEmailError = emailBlurred

  return (
    <EmailStepWrapper>
      <h2>{title}</h2>
      {(newsletterDisclaimerVersion === 'v4' || newsletterDisclaimerVersion === 'v5') && (
        <h3>{subTitle}</h3>
      )}
      <Form
        onSubmit={handleOnSubmit}>
        <IconInput
          icon={<FaEnvelope />}
          name='email'
          placeholder={t('email.placeholder') ?? 'Email'}
          onChange={handleOnEmailChanged}
          value={email}
          onBlur={() => setEmailBlurred(true)}
          onFocus={() => setEmailBlurred(false)}
          hasError={shouldShowEmailError && !!emailError}
          isInputValid={isValidEmail(email)}
        />
        {shouldShowEmailError && emailError && <Error>{emailError}</Error>}
        {newsletterDisclaimerVersion === 'v2' && (
          <>
            <Disclaimer1>
              <Emoji>💡</Emoji>
              <p>
                {t('email.info')}
              </p>

            </Disclaimer1>
            <Disclaimer2>
              <Emoji>🔒</Emoji>
              <p>
                {t('email.disclaimer.part1')} <PrivacyLink href={privacyLink} target="_self">{t('privacyPolicy')}</PrivacyLink>{t('email.disclaimer.part2')}
              </p>

            </Disclaimer2>
          </>
        )}
        {(newsletterDisclaimerVersion === 'v1' ||
          newsletterDisclaimerVersion === 'v3' ||
          newsletterDisclaimerVersion === 'v4' ||
          newsletterDisclaimerVersion === 'v5') && (
          <DisclaimerOld>
            <img src={images.lock} alt={t('altTexts.lock') || ''} />

            <p>
              {t('email.disclaimer.part1')} <PrivacyLink href={privacyLink} target="_self">{t('privacyPolicy')}</PrivacyLink>{t('email.disclaimer.part2')}
            </p>

          </DisclaimerOld>
        )}
      </Form>
    </EmailStepWrapper>
  )
}

export default EmailStep
