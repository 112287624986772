import { onboarding } from 'Containers/steps/Consts'
import { getUserLocaleDateFormat, getUserLocaleForDateFns, isMobile } from 'Lib'
import { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { useTranslation } from 'react-i18next'
import { FaRegCalendar } from 'react-icons/fa'
import 'react-multi-date-picker/styles/colors/teal.css'
import styled from 'styled-components'
import Button from './Button'
import DatePickerStyle from './DatePickerStyle'
import Logo from './Logo'
import SkipComponent from './SkipComponent'

const Container = styled.div`
  margin: auto;
  width: 100%;
`

const CalendarInputWrapper = styled.div`
  position: relative;
`

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: start;
  align-items: center;
  margin: ${({ theme }) => theme.spacing.large} 0;
`

const Icon = styled.div`
  position: absolute;
  right:  ${({ theme }) => theme.spacing.zero};
  top: 50%;
  transform: translateY(-50%);
  width: ${({ theme }) => theme.spacing.medium};
  height: ${({ theme }) => theme.spacing.medium};
  pointer-events: none;
  color: ${({ theme }) => theme.colors.text};
  align-items: center;
  display: flex;
  justify-content: center;
`

const StyledDatePicker = styled(DatePicker)`
  width: 100%;
  height: 30px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.lightGray};
  padding-left: ${({ theme }) => theme.spacing.small};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  color: ${({ theme }) => theme.colors.text};

  &:focus {
    outline: none;
    border: 1px solid ${({ theme }) => theme.colors.primary};
  }
`

interface OccasionDateSelectionProps {
  onNext: (date?: Date) => void
}

const OccasionDateSelection: React.FC<OccasionDateSelectionProps> = ({ onNext }) => {
  const [selectedDate, setSelectedDate] = useState(new Date())

  const { t } = useTranslation(onboarding)

  useEffect(() => {
    const date = new Date()
    date.setMonth(date.getMonth() + 3)
    setSelectedDate(date)
  }, [])

  const userLocale = getUserLocaleForDateFns()
  const format = getUserLocaleDateFormat()

  return (
    <Container>
      <Logo />
      <Wrapper>
        <h2>{t('special-occasion-date.title')}</h2>
        <p>{t('special-occasion-date.subtitle')}</p>
        <CalendarInputWrapper>
          <DatePickerStyle>
            <StyledDatePicker
              selected={selectedDate}
              locale={userLocale}
              dateFormat={format}
              minDate={new Date()}
              onFocus={(e) => {
                if (isMobile()) {
                  // block keyboard from appearing
                  e.target.readOnly = true
                }
              }}
              onChange={(date) => {
                if (date instanceof Date) {
                  setSelectedDate(date)
                }
              }} />
            <Icon>
              <FaRegCalendar />
            </Icon>
          </DatePickerStyle>
        </CalendarInputWrapper>
      </Wrapper>

      <SkipComponent onClick={() => onNext()} text='skip_question'/>
      <Button margin='0' onClick={() => onNext(selectedDate)}>
        {t('continueButton')}
      </Button>

    </Container >
  )
}

export default OccasionDateSelection
