import React, { useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'

interface EnhancedProgressBarWithSpinnerProps {
  duration: number
  onCompleted: () => void
  title: string
  paused?: boolean
}

const EnhancedProgressBarWithSpinnerContainer = styled.div`
  position: relative;
  margin-bottom: ${props => props.theme.spacing.medium};
  width: 100%;
`

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`

const SpinnerPercentageWrapper = styled.div`
  display: flex;
  align-items: center;
`

const SpinnerIcon = styled.div`
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-top: 2px solid ${props => props.theme.colors.primary};
  border-radius: 50%;
  width: 10px;
  height: 10px;
  animation: ${spin} 1s linear infinite;
  margin-right: 5px;
`

const PercentageText = styled.div<{ percentage: number }>`
  font-weight: normal;
  min-width: 35px;
  text-align: right;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: ${props => (props.percentage < 100 && props.percentage > 0 ? 'bold' : 'normal')};
`

const ProgressBarContainer = styled.div`
  height: 0.8rem;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.lightGray};
  border-radius: 10px;
  overflow: hidden;
`

const ProgressBarFill = styled.div<{ percentage: number }>`
  width: ${props => props.percentage}%;
  height: 100%;
  background-color: ${props => props.theme.colors.primary};
  border-radius: 10px;
  transition: width 0.1s ease-in-out;
`

const SpinnerInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  margin-bottom: ${props => props.theme.spacing.xsmall};
`

const TitleText = styled.div<{ percentage: number }>`
  text-align: left;
  font-weight: ${props =>
    (props.percentage < 100 && props.percentage !== 0 ? 'bold' : 'normal')
  };
  color: ${props => (props.percentage === 0 ? props.theme.colors.lightGray : 'inherit')};
`

const SpinnerPercentage: React.FC<{ percentage: number }> = ({ percentage }) => (
  <SpinnerPercentageWrapper>
    {percentage < 100 && percentage > 0 && <SpinnerIcon />}
    <PercentageText percentage={percentage}>
      {`${Math.floor(percentage)}%`}
    </PercentageText>
  </SpinnerPercentageWrapper>
)

const EnhancedProgressBarWithSpinner: React.FC<EnhancedProgressBarWithSpinnerProps> = ({ onCompleted, duration, title, paused = false }) => {
  const [percentage, setPercentage] = useState(0)

  useEffect(() => {
    // Calculate progress increment coefficient:
    // 1. Base calculation ensures smooth progression
    // 2. Constrain between 0.02 and 5 to prevent jumps
    // 3. Adjust based on remaining progress
    const interval = setInterval(() => {
      if (!paused) {
        setPercentage((prevPercentage) => {
          let coefficient = (100 - prevPercentage) / (Math.random() * 100 + 1)

          coefficient = Math.max(coefficient, 0.02)
          coefficient = Math.min(coefficient, 5)

          const nextPercentage = prevPercentage + coefficient
          return Math.min(nextPercentage, 100)
        })
      }
    }, (duration) / 100)

    return () => clearInterval(interval)
  }, [duration, onCompleted, paused])

  useEffect(() => {
    if (percentage >= 100) {
      onCompleted()
    }
  }, [percentage])

  return (
    <EnhancedProgressBarWithSpinnerContainer>
      <SpinnerInfoWrapper>
        <TitleText percentage={percentage}>{title}</TitleText>
        <SpinnerPercentage percentage={percentage} />
      </SpinnerInfoWrapper>
      <ProgressBarContainer>
        <ProgressBarFill percentage={percentage} />
      </ProgressBarContainer>
    </EnhancedProgressBarWithSpinnerContainer>
  )
}

export default EnhancedProgressBarWithSpinner
