import { onboarding } from 'Containers/steps/Consts'
import { getUserLanguage } from 'Lib'
import { mapNumber1AppImage, mapThirtyFiveMillionDownloadsImage } from 'Lib/ImageUtils'
import React from 'react'
import 'react-circular-progressbar/dist/styles.css'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import LoadingBar from './LoadingBar'
import ReviewCarousel from './ReviewCarousel'

const ProgressScreenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing.xLarge};
`

const TitleText = styled.p`
  margin-top: ${({ theme }) => theme.spacing.xLarge};
  margin-bottom: ${({ theme }) => theme.spacing.xLarge};
`

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing.xLarge};
  margin-bottom: ${({ theme }) => theme.spacing.xLarge};
`

const Image = styled.img`
  max-width: 50%;
  height: 180px;
  object-fit: contain;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    height: 120px;
    width: auto;
  }
`

interface ProgressStepProps {
  duration?: number
  whenDone: () => void
}

const ProgressStep: React.FC<ProgressStepProps> = ({ duration = 10000, whenDone }) => {
  const lang = getUserLanguage()

  const { t } = useTranslation(onboarding)

  const thirtyFiveMillionDownloadsImage = mapThirtyFiveMillionDownloadsImage(lang)
  const number1AppImage = mapNumber1AppImage(lang)

  return (
    <ProgressScreenWrapper>
      <TitleText>{t('progress_step.creating_fasting_plan')}</TitleText>
      <LoadingBar onCompleted={whenDone} duration={duration} />
      <ImageWrapper>
        <Image src={thirtyFiveMillionDownloadsImage} alt={t('altTexts.laurel') || ''} />
        <Image src={number1AppImage} alt={t('altTexts.numberOneApp') || ''} />
      </ImageWrapper>
      <ReviewCarousel duration={duration} />
    </ProgressScreenWrapper>
  )
}

export default ProgressStep
