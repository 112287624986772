import { useAppState } from 'AppContextProvider'
import { images } from 'images'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Logo from './Logo'

const HoursTrackedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Title = styled.h1`
  font-size: ${props => props.theme.fontSizes.xLarge};
  width: 100%;
  font-weight: bold;
  color: ${props => props.theme.colors.primary};
  padding-top: ${props => props.theme.spacing.xLarge};
  text-align: center;

  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    font-size: ${props => props.theme.fixedFontSizes.xLarge};
  }
`

const Subtitle = styled.h2`
  font-size: ${props => props.theme.fontSizes.medium};
  font-weight: bold;
  text-align: center;
  width: 100%;
  margin: ${props => props.theme.spacing.zero};
`

const HoursTrackedImage = styled.img`
  width: 120%;
  max-height: 400px;
  margin: ${props => props.theme.spacing.medium + ' ' + props.theme.spacing.zero};
  object-fit: contain;
`

const HoursTrackedStep: React.FC = () => {
  const { t } = useTranslation()
  const { appState } = useAppState()

  const image = appState.selections?.gender === 'f' ? images.ladies : images.couple
  const coupleAltText = appState.selections?.gender === 'f' ? t('altTexts.ladies') : t('altTexts.couple')

  return (
    <>
      <Logo />
      <HoursTrackedWrapper>
        <Title>{t('tracked_hours.number_of_hours', { ns: 'onboarding' })}</Title>
        <Subtitle>{t('tracked_hours.subtitle', { ns: 'onboarding' })}</Subtitle>
        <HoursTrackedImage src={image} alt={coupleAltText} />
      </HoursTrackedWrapper>
    </>
  )
}

export default HoursTrackedStep
