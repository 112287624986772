import { ActionType, Selectors } from 'Reducers'
import { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { FaCheckCircle, FaRegCircle } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import Button from 'Components/Button'
import { useDispatch } from 'store'
import { useAppState } from 'AppContextProvider'
import Loading from 'Components/Loading.component'
import { CustomPackage } from 'types/onboardingTypes'
import { theme } from 'styles/theme'
import { Spacer } from 'Components/Spacer'
import Text from 'Components/Text'
import invariant from 'invariant'
import { getUpsellFunnelStep1Data } from 'Lib/PaymentUtils'
import { useFeatureValue } from '@growthbook/growthbook-react'

const PRICE_BOX_HORIZONTAL_MARGIN = theme.spacing.mediumSmall
const PRICE_BOX_WIDTH = 'max(35%, 134px)'
const PRICE_BOX_HEIGHT = 175 // px

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: pre-line;
`

const InnerContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin: ${({ theme }) => theme.spacing.small};
  margin-top: ${({ theme }) => theme.spacing.medium};
  max-width: ${({ theme }) => theme.maxWidth};
`

const TextTangerian = styled.span`
  color: ${({ theme }) => theme.colors.secondary};
`

const TextCyan = styled.span`
  color: ${({ theme }) => theme.colors.primary};
`

const DiscountComparisonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`

const DiscountComparisonInnerContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
`

const PriceBox = styled.div<{ height: string, backgroundColor: string }>`
  display: flex;
  position: relative;
  align-items: flex-start;
  justify-content: center;
  width: ${PRICE_BOX_WIDTH};
  height: ${({ height }) => height};
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 16px;
`

const DiscountBadge = styled.div`
  display: flex;
  position: absolute;
  top: -24px;
  align-items: center;
  justify-content: center;
  min-width: max(50%, 80px);
  height: 40px;
  padding: ${({ theme }) => `0px ${theme.spacing.xsmall}`};
  border-radius: 24px;
  background: ${({ theme }) => theme.colors.yellow100};
  box-shadow: 0px 59px 16px 0px rgba(0, 0, 0, 0.00), 0px 38px 15px 0px rgba(0, 0, 0, 0.01), 0px 21px 13px 0px rgba(0, 0, 0, 0.05), 0px 9px 9px 0px rgba(0, 0, 0, 0.09), 0px 2px 5px 0px rgba(0, 0, 0, 0.10);
`

const DiscountBadgeSmall = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 26px;
  border-radius: 24px;
  background: ${({ theme }) => theme.colors.yellow500};
`

const BadgeText = styled.span<{ color: string }>`
  color: ${({ color }) => color};

  /* text-xs/semibold */
  font-size: ${({ theme }) => theme.responsiveFontSizes.small};
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 18px */
`

const PriceContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.grey50};
  border-radius: 8px;
  padding: 24px 16px;
  gap: 12px;

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Line = styled.div`
  border-bottom: 0.5px solid ${({ theme }) => theme.colors.grey200};
  margin: ${({ theme }) => theme.spacing.xsmall} 0;
  width: 100%;
`

const List = styled.ul`
  list-style-type: none;
  padding-left: ${({ theme }) => theme.spacing.small};
  width: 100%;

  li {
    display: flex;
    align-items: flex-start;
    color: ${({ theme }) => theme.colors.grey600};
    list-style-position: inside;
    margin-top: ${({ theme }) => theme.spacing.small};
    padding-left: 0; /* Removes unnecessary padding */
    font-weight: 400;
  }

 li::before {
    content: '●';
    color: ${({ theme }) => theme.colors.grey600};
    margin-right: 15px; /* Space between bullet and text */
    font-size: 0.8em; /* Adjust bullet size */
    margin-top: 0;
  }
`

const CheckedIcon = styled(FaCheckCircle)`
  width: 25px;
  height: 25px;
`

const UncheckedIcon = styled(FaRegCircle)`
  width: 25px;
  height: 25px;
`

const getStepOfferPrice = (stepId: SubscriptionUpdateStepId, prices: CustomPackage[]): CustomPackage => {
  switch (stepId) {
    case 'step1':
      return (prices.find(p => p.interval === 'month' && p.numberOfPeriods === 3)) as CustomPackage
    default: throw new Error('Invalid offer step')
  }
}

const SubscriptionUpdate: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { appState } = useAppState()

  const history = useHistory()
  const { stepId } = history.location.state as { stepId: SubscriptionUpdateStepId }

  const showTerms = useFeatureValue('upsell-funnel-show-terms', false)

  const [agreedToTerms, setAgreedToTerms] = useState(false)
  const [showTermsError, setShowTermsError] = useState(false)

  useEffect(() => {
    dispatch({ type: ActionType.FETCH_SUBSCRIPTION_DETAILS })
  }, [])

  const subscriptionDetails = useSelector(Selectors.getSubscriptionDetails)

  if (!subscriptionDetails || !appState.stripePrices) {
    return <Loading />
  }

  const offerPrice = getStepOfferPrice(stepId, appState.stripePrices)
  const oldPrice = appState.stripePrices.find(p => p.id === (subscriptionDetails.metadata.trialId ?? subscriptionDetails.metadata.priceId)) as CustomPackage
  const isDiscountApplied = !!subscriptionDetails?.metadata.discountPercentage

  if (isDiscountApplied) {
    invariant(offerPrice.discountedPrice && oldPrice.discountedPrice, 'Discounted price not found')
  }

  const {
    offerPriceFormatted,
    offerPriceOnRenewFormatted,
    oldPriceFormatted,
    oldPriceForThreeMonthsFormatted,
    priceDiffFormatted,
    offerPriceStringRounded,
    oldPriceForThreeMonthsRounded,
    discountPercentage
  } = getUpsellFunnelStep1Data(oldPrice, offerPrice, isDiscountApplied)

  const acceptOffer = () => {
    if (showTerms && !agreedToTerms) {
      setShowTermsError(true)
    } else {
      dispatch({ type: ActionType.UPDATE_SUBSCRIPTION, priceId: offerPrice.id, stepId })
    }
  }

  const declineOffer = () => {
    dispatch({ type: ActionType.DECLINE_UPDATE_SUBSCRIPTION_OFFER, stepId })
  }

  const onTermsPress = () => {
    if (agreedToTerms) {
      setAgreedToTerms(false)
      logEvent('web_upsellFunnelTermsDeclined')
    } else {
      setShowTermsError(false)
      setAgreedToTerms(true)
      logEvent('web_upsellFunnelTermsAccepted')
    }
  }

  return (
    <Center>
      <InnerContainer>
        <Text fontSize={theme.fontSizes.large} color={theme.colors.grey600} fontWeight='500'>
          <Trans
            i18nKey= {'upsellFunnel.text1'}
            components={[<TextTangerian />]}
            values={{ discountPercentage }}
          />
        </Text>

        <Spacer height={theme.spacing.large} />

        <Text fontSize={theme.fontSizes.medium} color={theme.colors.grey500} fontWeight='400'>
          {t('upsellFunnel.text2')}
        </Text>

        <Spacer height={theme.spacing.large} />

        <Text fontSize={theme.fontSizes.medium} color={theme.colors.grey600} fontWeight='600'>
          <Trans
            i18nKey= {'upsellFunnel.text3'}
            components={[<TextCyan />]}
          />
        </Text>

        <Spacer height={theme.spacing.large} />

        <DiscountComparisonContainer>
          <DiscountComparisonInnerContainer>
            <PriceBox height={`${PRICE_BOX_HEIGHT}px`} backgroundColor={theme.colors.grey400} style={{ marginRight: PRICE_BOX_HORIZONTAL_MARGIN }}>
              <Text fontSize={theme.fontSizes.medium} color={theme.colors.white} marginTop={theme.spacing.mediumLarge}>
                {oldPriceForThreeMonthsRounded}
              </Text>
            </PriceBox>

            <PriceBox height={`${Math.max(60, PRICE_BOX_HEIGHT * (1 - discountPercentage / 100))}px`} backgroundColor={theme.colors.green500} style={{ marginLeft: PRICE_BOX_HORIZONTAL_MARGIN }}>
              <DiscountBadge>
                <BadgeText color={theme.colors.yellow600}>
                  {t('saveNPercentage', { n: discountPercentage })}
                </BadgeText>
              </DiscountBadge>
              <Text fontSize={theme.fontSizes.medium} color={theme.colors.white} marginTop={theme.spacing.mediumLarge}>
                {offerPriceStringRounded}
              </Text>
            </PriceBox>
          </DiscountComparisonInnerContainer>

          <Spacer height={theme.spacing.xsmall} />

          <DiscountComparisonInnerContainer>
            <Text fontSize={theme.fontSizes.medium} color={theme.colors.grey600} width={PRICE_BOX_WIDTH} textAlign='center' style={{ marginRight: PRICE_BOX_HORIZONTAL_MARGIN }}>
              {t('upsellFunnel.nonDiscounted3Months')}
            </Text>

            <Text fontSize={theme.fontSizes.medium} color={theme.colors.grey600} width={PRICE_BOX_WIDTH} textAlign='center' style={{ marginLeft: PRICE_BOX_HORIZONTAL_MARGIN }}>
              <Trans
                i18nKey= {'upsellFunnel.discounted3Months'}
                components={[<TextCyan />]}
              />
            </Text>

          </DiscountComparisonInnerContainer>

        </DiscountComparisonContainer>

        <Spacer height={theme.spacing.xLarge} />

        <Text fontSize={theme.fontSizes.large} color={theme.colors.grey600} fontWeight='500' style={{ alignSelf: 'flex-start' }}>
          {t('upsellFunnel.priceContainerTitle')}
        </Text>

        <Spacer height={theme.spacing.mediumSmall} />

        <PriceContainer>
          <Row>
            <Text fontSize={theme.fontSizes.medium} color={theme.colors.grey900} fontWeight='600'>
              {t('upsellFunnel.priceContainerMain1')} <Text fontSize={theme.fontSizes.small}>{t('upsellFunnel.priceContainerSmall1')}</Text>
            </Text>
            <Text fontSize={theme.fontSizes.medium} color={theme.colors.grey900} fontWeight='600' textAlign='right'>
              {oldPriceForThreeMonthsFormatted}
            </Text>
          </Row>

          <Line />

          <Row>
            <Text fontSize={theme.fontSizes.medium} color={theme.colors.yellow500} fontWeight='600'>
              {t('upsellFunnel.priceContainerMain2')} <Text fontSize={theme.fontSizes.small} color={theme.colors.yellow500}>{t('upsellFunnel.priceContainerSmall2')}</Text>
            </Text>
            <Text fontSize={theme.fontSizes.medium} color={theme.colors.yellow500} fontWeight='600' textAlign='right'>
              -{priceDiffFormatted}
            </Text>
          </Row>

          <Row>
            <Text fontSize={theme.fontSizes.medium} color={theme.colors.grey900} fontWeight='600'>
              {t('upsellFunnel.priceContainerMain3')} <Text fontSize={theme.fontSizes.small}>{t('upsellFunnel.priceContainerSmall3')}</Text>
            </Text>
            <Text fontSize={theme.fontSizes.medium} color={theme.colors.grey900} fontWeight='600' textAlign='right'>
              -{oldPriceFormatted}
            </Text>
          </Row>

          <Line />

          <Row>
            <Text fontSize={theme.fontSizes.medium} color={theme.colors.grey900} fontWeight='600'>
              {t('upsellFunnel.priceContainerMain4')} <Text fontSize={theme.fontSizes.small}>{t('upsellFunnel.priceContainerSmall4')}</Text>
            </Text>

            <Column style={{ alignItems: 'flex-end' }}>
              <div style={{ alignItems: 'center' }}>
                <Row>
                  <DiscountBadgeSmall>
                    <BadgeText color={theme.colors.white}>
                      {`${discountPercentage} %`}
                    </BadgeText>
                  </DiscountBadgeSmall>
                  <Spacer width={theme.spacing.small} />
                  <Text color={theme.colors.grey900} fontWeight='600'>
                    {offerPriceFormatted}
                  </Text>
                </Row>
              </div>
            </Column>
          </Row>

          <div style={{ alignSelf: 'flex-end', marginTop: '-10px' }}>
            <Text fontSize={theme.fontSizes.small} color={theme.colors.grey400}>
              {`${t('lastPrice')}: `}
            </Text>
            <Text fontSize={theme.fontSizes.small} color={theme.colors.grey400} style={{ textDecorationLine: 'line-through', textDecorationColor: theme.colors.yellow500 }}>
              {oldPriceForThreeMonthsFormatted}
            </Text>
          </div>
        </PriceContainer>

        <Spacer height={theme.spacing.small} />

        <List>
          <li>{t('upsellFunnel.listItem1')}</li>
          <li>{t('upsellFunnel.listItem2')}</li>
          <li>{t('upsellFunnel.listItem3')}</li>
          <li>{t('upsellFunnel.listItem4', {
            acceptButtonText: t('upsellFunnel.acceptButton', { priceDiffFormatted }),
            offerPriceFormatted,
            offerPriceOnRenewFormatted
          })}</li>
          <li>{t('upsellFunnel.listItem5')}</li>
        </List>

        <Line />

        <Spacer height={theme.spacing.small} />

        {showTerms && <Row style={{ justifyContent: 'flex-start' }} onClick={onTermsPress}>
          {agreedToTerms
            ? <CheckedIcon color={theme.colors.green500} />
            : <UncheckedIcon color={theme.colors.grey600} />}
          <Spacer width={theme.spacing.small} />
          <Text fontWeight='600' color={theme.colors.grey800}>
            {t('upsellFunnel.termsText')}
          </Text>
        </Row>}

        {showTermsError && <Text color={theme.colors.yellow500} marginTop='10px'>{t('upsellFunnel.termsError')}</Text>}

        <Button margin='20px 0 0 0' onClick={acceptOffer} primaryColor={theme.colors.yellow500}>
          {t('upsellFunnel.acceptButton', { priceDiffFormatted })}
        </Button>
        <Button margin='20px 0 0 0' onClick={declineOffer} theme='outline' primaryColor={theme.colors.grey600} borderColor={theme.colors.grey200} hideLoader>
          {t('upsellFunnel.declineButton')}
        </Button>
      </InnerContainer>
    </Center >
  )
}

export default SubscriptionUpdate
