import { useAppState } from 'AppContextProvider'
import { images } from 'images'
import { getRecurringAndTrialId, getUserLanguage } from 'Lib'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { BeatLoader, ClipLoader } from 'react-spinners'
import { Selectors } from 'Reducers'
import { createStripeSubscriptionHandler, getStripeElementsReturnUrl } from 'Services'
import styled from 'styled-components'
import { theme } from 'styles/theme'
import { CustomPackage, PackageTagId } from 'types/onboardingTypes'

import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { DefaultValuesOption, StripeError, StripePaymentElementOptions } from '@stripe/stripe-js'

import PaymentDisclaimer from '../PaymentDisclaimer'

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const SubmitButton = styled.button`
  background-color: ${props => props.theme.colors.primary};
  font-size: ${props => props.theme.fontSizes.medium};
  color: #fff;
  border: none;
  height: 50px;
  border-radius: ${props => props.theme.spacing.small};
  cursor: pointer;
  &:hover {
    background: ${({ theme }) => theme.colors.primaryDark};
    border: 1px solid ${({ theme }) => theme.colors.primaryDark};
  };
  margin-top: ${props => props.theme.spacing.medium};
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: ${props => props.theme.spacing.xsmall};
`

const PaymentErrorMessage = styled.span`
  padding: ${props => props.theme.spacing.small};
  padding-top: 0;
  padding-left: 0;
  color: ${props => props.theme.colors.text};
  font-size: ${props => props.theme.fontSizes.small}
`

const ErrorContainer = styled.div`
  margin-top: ${props => props.theme.spacing.small};
  margin-bottom: calc(${props => props.theme.spacing.small} + ${props => props.theme.spacing.xsmall});
`

const LoadingWrapper = styled.div`
  height: 44px;
  flex-direction: row;
  justify-content: center;
  display: flex;
  flex: 1;
`

const ErrorContent = styled.div`
  background-color: #FFFF0044;
  padding: 15px;
  border-radius: 15px; 
`

const PaymentErrorDetails = styled.div`
  margin-top: ${props => props.theme.spacing.small};
  font-size: ${props => props.theme.fontSizes.small};
`

const ApplePayButtonComponent = styled.button`
  background-size: cover;
  border-radius: ${props => props.theme.spacing.small};
  border: 1px solid black;
  background-color: black;
  color: white;
  height: 50px;
  width: 100%;
  cursor: pointer;
  font-size: ${props => props.theme.fontSizes.medium};

  img {
    width: ${props => props.theme.spacing.large};
    height: ${props => props.theme.spacing.large};
    margin-top: -${props => props.theme.spacing.xsmall};
  }

  p {
    margin: 0;
  }
`

const GooglePayButtonComponent = styled.button`
  background-size: cover;
  border-radius: ${props => props.theme.spacing.small};
  border: 2px solid #black;
  background-color: white;
  color: white;
  height: 50px;
  width: 100%;
  cursor: pointer;
  font-size: ${props => props.theme.fontSizes.medium};

  img {
    height: ${props => props.theme.spacing.large};
  }

  p {
    margin: 0;
  }
`

const PaypalButtonComponent = styled.button`
  background-size: cover;
  border-radius: ${props => props.theme.spacing.small};
  border: 1px solid black;
  background-color: white;
  border-color: #0070BA;
  color: white;
  height: 50px;
  width: 100%;
  margin-top: ${props => props.theme.spacing.medium};
  cursor: pointer;
  font-size: ${props => props.theme.fontSizes.medium};

  img {
    height: ${props => props.theme.spacing.large};
  }

  p {
    margin: 0;
  }
`

interface PaymentMethodsProps {
  selectedPackage: CustomPackage
  paypalAsFirstButton: boolean
  email: string
  onScrollDownRequest?: () => void
  externalStripeError?: StripeError
  formattedPrices: {
    formattedBasePrice: string
    formattedTrialPrice: string | null
    formattedIntroPrice: string | null
  }
  formLayout: 'tabs' | 'accordion'
  recurringInterval?: string
}

type payment_methods = 'card' | 'paypal' | 'apple_pay' | 'google_pay'

const PaymentMethods = ({
  selectedPackage,
  paypalAsFirstButton,
  email,
  onScrollDownRequest,
  externalStripeError,
  formattedPrices,
  formLayout,
  recurringInterval
}: PaymentMethodsProps) => {
  const stripe = useStripe()
  const elements = useElements()
  const { t } = useTranslation()

  const [errorMessage, setErrorMessage] = useState<string | undefined | null>()
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<payment_methods | null>(null)
  const [submitting, setSubmitting] = useState(false)
  const [key, setKey] = useState((new Date()).getMilliseconds())
  const [elementsLoading, setElementsLoading] = useState(true)
  const [disabledErrorDetails, setDisabledErrorDetails] = useState(false)
  const [isFormVisible, setIsFormVisible] = useState(false)
  const lang = getUserLanguage()
  const { appState } = useAppState()
  const errorMessageRef = useRef<any>(null)
  const submitButtonRef = useRef<any>(null)
  const formRef = useRef<any>(null)
  const gender = useSelector(Selectors.getGender)

  const { id: selectedPackageId, interval, numberOfPeriods, discountedPrice, tagId } = selectedPackage

  // On iPhone, stripe is keeping its state when going back and forth between
  // the payment page and the 3rd party check page (e.g. paypal). This is causing the payment element
  // to be disabled. This is a workaround to force the payment element to be reinitialized.
  // Fixing this issue: https://bodyfastworkspace.slack.com/archives/C04J5V1PN8G/p1700477131376659
  const refreshStripeElements = (event: any) => {
    if (event.persisted) {
      var date = new Date()
      setKey(date.getMilliseconds())
      setElementsLoading(true)
      setSubmitting(false)
      logEvent('web_stripeElementsRefreshed')
    }
  }

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0]
      setIsFormVisible(entry.isIntersecting)
    })

    if (formRef.current) {
      observer.observe(formRef.current)
    }

    return () => {
      if (formRef.current) {
        observer.unobserve(formRef.current)
      }
    }
  }, [formRef.current])

  useEffect(() => {
    window.addEventListener('pageshow', refreshStripeElements)
    return () => {
      window.removeEventListener('pageshow', refreshStripeElements)
    }
  }, [])

  useEffect(() => {
    if (!email) {
      throw new Error('Email cannot be empty')
    }
  }, [])

  useEffect(() => {
    if (externalStripeError) {
      handleError(externalStripeError)
    }
  }, [externalStripeError])

  useEffect(() => {
    if (errorMessageRef.current && errorMessage) {
      onScrollDownRequest?.()
    }
  }, [errorMessageRef, errorMessage])

  useEffect(() => {
    if (isFormVisible) {
      onScrollDownRequest?.()
    }
  }, [selectedPaymentMethod])

  const handlePaymentElementLoadError = (error: StripeError) => {
    void logError('web_stripe_paymentLoadError', error.message)
  }

  const handleError = (error: StripeError) => {
    logEvent('web_stripe_paymentError', error)
    setSubmitting(false)

    // these are errors on the form itself -> errors are already shown on the UI
    if (error.type === 'validation_error') {
      return
    }

    //  payment_intent_authentication_failure is for cancelled 3d-secure authentications (no differentiation)
    if (error.code === 'payment_intent_authentication_failure') {
      setDisabledErrorDetails(true)
      setErrorMessage(t('StripeError.payment_intent_authentication_failure'))
      return
    }

    // incomplete = cancelled purchase
    if (error.code !== 'incomplete') {
      setDisabledErrorDetails(false)
      setErrorMessage(error.message)
    }
  }

  const resetErrorState = () => {
    setErrorMessage('')
    setDisabledErrorDetails(false)
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setSubmitting(true)

    let type; let subscriptionId; let clientSecret

    try {
      const { recurringPackageId, trialId } = getRecurringAndTrialId(
        selectedPackage,
        selectedPackageId,
        appState.stripePrices!
      );

      ({ type, subscriptionId, clientSecret } = await createStripeSubscriptionHandler(
        recurringPackageId,
        email,
        lang,
        trialId,
        appState.discountAvailable,
        gender
      ))
    } catch (err) {
      setSubmitting(false)
      setDisabledErrorDetails(true)
      setErrorMessage(t('StripeError.generalError'))
      logError('web_stripe_paymentLoadError', err, undefined, true)
      return
    }

    try {
      if (!stripe || !elements) {
        logError('web_stripe_not_loaded', 'Stripe or Elements not initialized', { stripe: !!stripe, elements: !!elements }, true)
        return
      }

      logEvent('web_startCheckout', { channel: 'stripe', ...selectedPackage })

      // Trigger form validation and wallet collection
      const { error: submitError } = await elements.submit()
      if (submitError) {
        setSubmitting(false)
        handleError(submitError)
        return
      }

      const confirmIntent = type === 'setup' ? stripe.confirmSetup : stripe.confirmPayment

      // Confirm the Intent using the details collected by the Payment Element
      const res = await confirmIntent({
        elements,
        clientSecret,
        confirmParams: {
          return_url: getStripeElementsReturnUrl(subscriptionId, selectedPackageId)
        },
        redirect: 'always'
      })

      setSubmitting(false)
      if (res.error) {
        // This point is only reached if there's an immediate error when confirming the Intent.
        // Show the error to your customer (for example, "payment details incomplete").
        handleError(res.error)
      }
    } catch (error) {
      handleError(error)
    }
  }

  // type definitions do not contain the card property yet. This is a workaround to set the default card network
  interface CardDefaultValue {
    card: {
      network: string[]
    }
  }

  const defaultValues: DefaultValuesOption & CardDefaultValue = {
    billingDetails: {
      email
    },
    card: {
      network: ['cartes_bancaires', 'visa', 'mastercard']
    }
  }

  const paymentElementOptions: StripePaymentElementOptions = {
    paymentMethodOrder: paypalAsFirstButton ? ['apple_pay', 'paypal', 'card', 'google_pay'] : ['apple_pay', 'card', 'paypal', 'google_pay'],
    layout: {
      type: formLayout,
      defaultCollapsed: false
    },
    defaultValues,
    terms: {
      applePay: 'never',
      googlePay: 'never',
      paypal: 'never',
      card: 'never'
    }
  }

  return (
    <form id='payment-form' onSubmit={handleSubmit}>
      <FormWrapper ref={formRef}>
        <PaymentElement
          key={key}
          id='payment-element'
          options={paymentElementOptions}
          onChange={(e) => setSelectedPaymentMethod(e.value.type as payment_methods)}
          onReady={() => setElementsLoading(false)}
          onLoadError={(e) => handlePaymentElementLoadError(e.error)}
          // I believe our issue can be caught here. I suggest just logging the error for now and understand better.
          // I suspect users can just refresh the page and it will work and we would be loosing them if we just skip this page.
          // Ticket in question: https://github.com/matttti/BodyFast/issues/4086
          onFocus={() => resetErrorState()}/>
        {elementsLoading && (
          <LoadingWrapper>
            <ClipLoader
              color={theme.colors.primary}
              size={40}
              speedMultiplier={1} />
          </LoadingWrapper>
        )}
        {selectedPaymentMethod === 'apple_pay' && (
          <ApplePayButtonComponent disabled={submitting || !stripe || !elements} type="submit" onClick={resetErrorState}>
            <ButtonWrapper>
              {submitting
                ? <BeatLoader size={8} color="#FFFFFF" />
                : <>
                  <img src={images.appleLogo} alt={t('altTexts.appleLogo') || ''} />
                  <p>{t('modal.applePayButton')}</p>
                </>
              }
            </ButtonWrapper>
          </ApplePayButtonComponent>
        )}
        {selectedPaymentMethod === 'google_pay' && (
          <GooglePayButtonComponent disabled={submitting || !stripe || !elements} type="submit" onClick={resetErrorState}>
            <ButtonWrapper>
              {submitting
                ? <BeatLoader size={8} color="#CCCCCC" />
                : <>
                  <img src={images.googlePayLogo} alt={t('altTexts.googlePayLogo') || ''} />
                  {/* <p>{t('modal.googlePayButton')}</p> */}
                </>
              }
            </ButtonWrapper>
          </GooglePayButtonComponent>
        )}
        {selectedPaymentMethod === 'paypal' && (
          <PaypalButtonComponent disabled={submitting || !stripe || !elements} type="submit" onClick={resetErrorState}>
            <ButtonWrapper>
              {submitting
                ? <BeatLoader size={8} color="#0070BA" />
                : <img src={images.paypal} alt={t('altTexts.paypal') || ''} />
              }
              {/* <p>{t('modal.paypalButton')}</p> */}
            </ButtonWrapper>
          </PaypalButtonComponent>
        )}
        {selectedPaymentMethod === 'card' && (
          <SubmitButton ref={submitButtonRef} disabled={submitting || !stripe || !elements} id='submit' onClick={resetErrorState}>
            {submitting ? <BeatLoader size={8} color="#ffffff" />
              : <ButtonWrapper>
                {t('continueButton')}
              </ButtonWrapper>
            }
          </SubmitButton>
        )}
        <PaymentDisclaimer
          selectedInterval={interval}
          recurringInterval={recurringInterval}
          numberOfPeriods={numberOfPeriods}
          showDiscountDisclaimer={!!discountedPrice || tagId === PackageTagId.Trial}
          {...formattedPrices} />
        {errorMessage && (
          <ErrorContainer ref={errorMessageRef}>
            <ErrorContent>
              <PaymentErrorMessage id='payment-message'>⚠️ {errorMessage}</PaymentErrorMessage>
              {!disabledErrorDetails && <PaymentErrorDetails>{t('StripeError.paymentDeclinedInfo')}</PaymentErrorDetails>}
            </ErrorContent>
          </ErrorContainer>
        )}
      </FormWrapper>
    </form>
  )
}

export default PaymentMethods
