import Logo from 'Components/Logo'
import MarkdownWrapper from 'Components/MarkdownWrapper'
import { getUserLanguage } from 'Lib'
import React from 'react'
import styled from 'styled-components'

const Center = styled.div`
  display: flex;
  justify-content: center;
`
const MaxWidth = styled.div`
  padding: 40px 20px;
  width: 100%;
  word-break: break-word;
  h1 {
    font-size: 1.5em;
  }
  h2 {
    font-size: 1.3em;
  }
  h3 {
    font-size: 1.17em;
  }
`

const PrivacyPolicyContainer: React.FC = () => {
  const language = getUserLanguage() === 'de' ? 'de' : 'en' // only german has a translation
  const privacyFilePath = `assets/legal/Privacy.web.${language}.md`

  return (
    <Center>
      <MaxWidth>
        <Logo/>
        <MarkdownWrapper filePath = {privacyFilePath} />
      </MaxWidth>
    </Center>
  )
}

export default PrivacyPolicyContainer
