import { useAppState } from 'AppContextProvider'
import { RouteNames } from 'RouteNames'
import { images } from 'images'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { OnboardingStep } from '../types/onboardingTypes'
import getStepConfigs from './OnboardingSteps'
import OnboardingStepsContainer from './OnboardingSteps.container'
import { onboarding } from './steps/Consts'
import { useFeatureValue } from '@growthbook/growthbook-react'

const OnboardingContainer: React.FC = () => {
  const { appState, setAppState } = useAppState()
  const [_, setStepsIdsToShowInProgressBar] = useState<string[]>([])
  const { t } = useTranslation(onboarding)
  const onboardingStepsVariant = useFeatureValue('onboarding-steps-variant', 'v1')
  const [steps, setSteps] = useState<OnboardingStep[]>(getStepConfigs({ onboardingStepsVariant }))
  const location = useLocation()
  const history = useHistory()
  const [prevLocation, setPrevLocation] = useState('')

  useEffect(() => {
    if (steps.length > 0) {
      const pathNames = location.pathname.split('/')
      if (pathNames.length === 2) {
        history.push(RouteNames.LOADING)
      } else {
        const currentStepId = location.pathname.split('/')[2] ?? steps[0].id
        const currentStep = steps.find((step) => step.id === currentStepId)

        const prevStepId = prevLocation.split('/')[2] ?? steps[0].id
        const prevStep = steps.find((step) => step.id === prevStepId)

        if (currentStep) {
          setAppState((prevState) => ({
            ...prevState,
            currentStep,
            prevStep
          }))
        }
      }
    }

    setPrevLocation(location.pathname)
  }, [location])

  useEffect(() => {
    const image = new Image()
    image.src = images.hoursTracked
    image.alt = t('altTexts.hoursTracked') || ''

    const pathNames = location.pathname.split('/')
    if (pathNames.length === 2) {
      history.push(RouteNames.LOADING)
    }
  }, [])

  useEffect(() => {
    if (!appState.selections) return
    const gender = appState.selections.gender

    if (gender) {
      const steps = getStepConfigs({ gender, onboardingStepsVariant })
      setSteps(steps)
    }
  }, [appState?.selections?.gender])

  useEffect(() => {
    const stepsToShowInProgressBar = steps.filter((step) => step.type !== 'custom')
    const stepsIdsToShowInProgressBar = stepsToShowInProgressBar.map((step) => step.id)

    if (stepsIdsToShowInProgressBar.length === 0) {
      return
    }

    setStepsIdsToShowInProgressBar(stepsIdsToShowInProgressBar)
    setAppState((prevState) => ({
      ...prevState,
      stepsIdsToShowInProgressBar,
      stepsToShowIds: steps.map((step) => step.id),
      steps
    }))
  }, [steps])

  return <OnboardingStepsContainer />
}

export default OnboardingContainer
