import styled from 'styled-components'

export const OptionLabel = styled.span<{rightOffset: number}>`
  justify-content: space-between; 
  align-items: center;
  width: calc(100% - ${({ rightOffset }) => rightOffset}px); // Subtract the offset amount from 100% to make space for the emoji
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
`
