import { t } from 'i18next'
import styled from 'styled-components'

const LineWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing.medium};
`

const Line = styled.hr`
  border: 0;
  border-top: 1px solid #ccc;
  width: 30%;
`

const OrText = styled.span`
  margin: 0 10px;
  text-align: center;
  color: ${({ theme }) => theme.colors.lightText};
  font-size: small;
`

const OrLineDivider: React.FC = () => {
  return (<LineWrapper>
    <Line />
    <OrText>{t('orContinueWith')}</OrText>
    <Line />
  </LineWrapper>)
}

export default OrLineDivider
