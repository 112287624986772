import { useAppState } from 'AppContextProvider'
import invariant from 'invariant'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { HiOutlineClock } from 'react-icons/hi'
import styled from 'styled-components'
import { PaymentColorPackage } from 'types/onboardingTypes'

const DiscountGiftContainer = styled.div<{ color: string }>`
  width: 100%;
  max-width: 404px;
  align-items: flex-start;
  margin: 0px ${({ theme }) => theme.spacing.xLarge};
  background-color: ${({ color }) => color}99;
  border-radius: ${({ theme }) => theme.spacing.small};
  display: flex;
  flex-direction: row;
  align-items: center;
`

const GiftIcon = styled.span`
  font-size: ${({ theme }) => theme.responsiveFontSizes.xxLarge};
  margin-right: ${({ theme }) => theme.responsiveSpacing.small};
  margin-left: ${({ theme }) => theme.responsiveSpacing.medium};
`
const GiftTextsContainer = styled.div<{ textColor: string }>`
  padding: ${({ theme }) => theme.responsiveSpacing.medium};
  padding-left: ${({ theme }) => theme.responsiveSpacing.medium};
  font-weight: bold;
  color: ${({ textColor }) => textColor};
  font-size: ${({ theme }) => theme.responsiveFontSizes.medium};
  
`
const GiftWithTimerTextsContainer = styled.div<{ textColor: string }>`
  padding: ${({ theme }) => theme.responsiveSpacing.medium};
  padding-left: 0;
  font-weight: bold;
  color: ${({ textColor }) => textColor};
  font-size: ${({ theme }) => theme.responsiveFontSizes.medium};
`

const DiscountGiftPercentage = styled.strong`
  color: #ff4f64;
  margin-right: 4px;
  margin-left: 4px;
  display: contents;
  font-size: ${({ theme }) => theme.fontSizes.mediumLarge};
`

const GiftTimeLeftContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing.small};
  font-weight: normal;
  font-size: ${({ theme }) => theme.responsiveFontSizes.medium};
`

const GiftTimeLeft = styled.strong`
  align-self: flex-start;
`

const DiscountCountdownContainer = styled.div<{ color: string }>`
  width: 100%;
  max-width: 404px;
  margin: 0px ${({ theme }) => theme.spacing.xLarge};
  background-color: ${({ color }) => color};
  border-radius: ${({ theme }) => theme.spacing.small};
`

const DiscountCountdownText = styled.strong`
  padding: ${({ theme }) => theme.spacing.small} ${({ theme }) => theme.spacing.small};
  font-size: ${({ theme }) => theme.responsiveFontSizes.medium};
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: normal;
`

const Icon = styled(HiOutlineClock)`
  width: 22px;
  height: 22px;
  margin-right: ${({ theme }) => theme.spacing.xsmall};
  margin-bottom: 1px;
`

interface DiscountBannerProps {
  colorPackage: PaymentColorPackage
  discountTimeLeft: string | null
  withDiscountTimer: boolean
  discountPercentage: number
  styleOverrides?: React.CSSProperties
}

enum BannerStyling {
  GIFT_AND_COUNTDOWN = 'gift_and_countdown',
  COUNTDOWN = 'countdown',
  GIFT = 'gift'
}

const DiscountBanner = ({ colorPackage, discountTimeLeft, withDiscountTimer, discountPercentage, styleOverrides = {} }: DiscountBannerProps) => {
  const { appState } = useAppState()
  const { t } = useTranslation()

  const getBannerStyling = (): BannerStyling => {
    if (appState.discountBannerType === 'gift' && withDiscountTimer) {
      invariant(discountTimeLeft !== '', 'discountTimeLeft is required when discountBannerType is gift_and_countdown')
      return BannerStyling.GIFT_AND_COUNTDOWN
    }

    if (withDiscountTimer) {
      return BannerStyling.COUNTDOWN
    }

    return BannerStyling.GIFT
  }

  switch (getBannerStyling()) {
    case BannerStyling.GIFT_AND_COUNTDOWN:
      return (
        <DiscountGiftContainer style={styleOverrides} color={colorPackage.bannerColor}>
          <GiftIcon>🎁</GiftIcon>
          <GiftWithTimerTextsContainer textColor={colorPackage.bannerTextColor} >
            <div>
              <Trans
                values={{ discountPercentage: `${discountPercentage}%` }}
                i18nKey='package.discountGift'
                components={[<DiscountGiftPercentage />]}
              />
            </div>
            <GiftTimeLeftContainer>
              <Trans
                values={{ timeString: discountTimeLeft }}
                i18nKey='package.discountGiftTimeout'
                components={[<GiftTimeLeft />]}
              />
            </GiftTimeLeftContainer>
          </GiftWithTimerTextsContainer>
        </DiscountGiftContainer>
      )

    case BannerStyling.COUNTDOWN:
      return (
        <DiscountCountdownContainer style={styleOverrides} color={colorPackage.bannerColor}>
          <DiscountCountdownText><Icon />{t('package.discountTimeout', { timeString: discountTimeLeft })}</DiscountCountdownText>
        </DiscountCountdownContainer>
      )

    case BannerStyling.GIFT:
      return (
        <DiscountGiftContainer style={styleOverrides} color={colorPackage.bannerColor}>
          <GiftIcon>🎁</GiftIcon>
          <GiftTextsContainer textColor={colorPackage.bannerTextColor} >
            <div>
              <Trans
                values={{ discountPercentage: `${discountPercentage}%` }}
                i18nKey='package.discountGift'
                components={[<DiscountGiftPercentage />]}
              />
            </div>
          </GiftTextsContainer>
        </DiscountGiftContainer>
      )
  }
}

export default DiscountBanner
