import React from 'react'
import styled from 'styled-components'
import ImageSlider from './ImageSlider'
import { mapOnboardingFinishedSliderData } from 'Lib/ImageUtils'
import { getUserLanguage } from 'Lib'

const LeadingWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing.medium};
  margin-bottom: ${({ theme }) => theme.spacing.medium};

  h1 {
    font-size: ${({ theme }) => theme.fontSizes.medium};
    color: ${({ theme }) => theme.colors.text};
    text-align: center;
    margin: 0;
    margin-bottom: ${({ theme }) => theme.spacing.medium};
  }
`

const UserOurApp: React.FC = () => {
  const lang = getUserLanguage()
  const data = mapOnboardingFinishedSliderData(lang)

  return (
    <LeadingWrapper>
      <ImageSlider data={data} />

    </LeadingWrapper>
  )
}

export default UserOurApp
