import { getUserLanguage } from 'Lib'
import { mapMoneyBackGuaranteeImage } from 'Lib/ImageUtils'
import { mapRefundPolicyLink } from 'Lib/LinkUtils'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const Wrapper = styled.div`
  color: ${({ theme }) => theme.colors.lightText};
  width: 100%;
  background-color: #f5f5f5;
  margin-top: ${({ theme }) => theme.spacing.medium};
  border-radius: ${({ theme }) => theme.spacing.small};
  max-width:  ${({ theme }) => theme.maxWidth};

  p, span {
    width: 100%;
    font-size: 1em;
    margin: 5px 0;
  }
`

const Padding = styled.div`
  padding-top: ${({ theme }) => theme.spacing.zero};
  padding-left: ${({ theme }) => theme.spacing.medium};
  padding-right: ${({ theme }) => theme.spacing.medium};
  padding-bottom: ${({ theme }) => theme.spacing.medium};
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Image = styled.img`
  height: 120px;
`

const Disclaimer = styled.p`
  color: ${({ theme }) => theme.colors.lightText};
  text-align: center;
}`

const Link = styled.a`
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: underline;
`

const MoneyBackGuarantee: React.FC = () => {
  const { t } = useTranslation()
  const lang = getUserLanguage()

  return (
    <Wrapper>
      <Padding>
        <Image src={mapMoneyBackGuaranteeImage(lang)} alt={t('altTexts.moneyBack') || ''} style={{ marginTop: '20px' }} />
        <Disclaimer>
          {t('moneyBackGuarantee.part1')} <Link target='_blank' href={mapRefundPolicyLink(lang)}>{t('moneyBackGuarantee.refundPolicy')}</Link> {t('moneyBackGuarantee.part3')}
        </Disclaimer>
      </Padding>
    </Wrapper >
  )
}

export default MoneyBackGuarantee
