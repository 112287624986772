import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import EnhancedProgressBarWithSpinner from './EnhancedProgressBarWithSpinner'
import { getUserLanguage } from 'Lib'
import { mapThirtyFiveMillionDownloadsImage, mapNumber1AppImage } from 'Lib/ImageUtils'
import ReviewCarousel from './ReviewCarousel'
import Modal from 'Components/Modal'
import OptionWrapper from './OptionWrapper'

const ProgressScreenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing.xLarge};
`

const TitleText = styled.h2`
  margin-top: ${({ theme }) => theme.spacing.xLarge};
  margin-bottom: ${({ theme }) => theme.spacing.xLarge};
`

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing.small};
  margin-bottom: ${({ theme }) => theme.spacing.small};
`

const Image = styled.img`
  max-width: 50%;
  height: 180px;
  object-fit: contain;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    height: 120px;
    width: auto;
  }
`

interface ProgressStepProps {
  duration?: number
  whenDone: () => void
}

const ProgressStep: React.FC<ProgressStepProps> = ({ whenDone }) => {
  const lang = getUserLanguage()
  const { t } = useTranslation()

  const PROGRESS_BARS = [
    t('progress_step.enhancedProgressBarTexts.first'),
    t('progress_step.enhancedProgressBarTexts.second'),
    t('progress_step.enhancedProgressBarTexts.third'),
    t('progress_step.enhancedProgressBarTexts.fourth')
  ]

  const thirtyFiveMillionDownloadsImage = mapThirtyFiveMillionDownloadsImage(lang)
  const number1AppImage = mapNumber1AppImage(lang)

  const [activeProgressBarIndex, setActiveProgressBarIndex] = useState(0)

  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowModal(true)
    }, 6000)

    return () => clearTimeout(timer)
  }, [])

  const handleProgressComplete = () => {
    setActiveProgressBarIndex(prevIndex => {
      if (prevIndex < PROGRESS_BARS.length - 1) {
        // Start the next progress bar
        return prevIndex + 1
      } else {
        // Call whenDone when the last progress bar completes
        whenDone()
      }
      return prevIndex
    })
  }

  const handleModalResponse = () => {
    setShowModal(false)
  }

  return (
    <ProgressScreenWrapper>
      <TitleText>{t('progress_step.analyzing_your_answers')}</TitleText>
      {PROGRESS_BARS.map((bar, index) => (
        <EnhancedProgressBarWithSpinner
          key={index}
          title={bar}
          paused={index !== activeProgressBarIndex || showModal}
          onCompleted={() => handleProgressComplete()}
          duration={3000}
        />
      ))}
      <ImageWrapper>
        <Image src={thirtyFiveMillionDownloadsImage} alt={t('altTexts.laurel') || ''} />
        <Image src={number1AppImage} alt={t('altTexts.numberOneApp') || ''} />
      </ImageWrapper>
      <ReviewCarousel duration={15000} />

      {showModal && (
        <Modal padded>
          <h3>{t('progress_step.modal_question.title')}</h3>
          <OptionWrapper onClick={handleModalResponse}>
            <span>{t('progress_step.modal_question.answer_1')}</span>
          </OptionWrapper>
          <OptionWrapper onClick={handleModalResponse}>
            <span>{t('progress_step.modal_question.answer_2')}</span>
          </OptionWrapper>
          <OptionWrapper onClick={handleModalResponse}>
            <span>{t('progress_step.modal_question.answer_3')}</span>
          </OptionWrapper>
        </Modal>
      )}
    </ProgressScreenWrapper>
  )
}

export default ProgressStep
