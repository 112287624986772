import InfoCard from '../Components/InfoCard.component'
import ActiveSubscriptionDisclaimer from 'Components/Download/ActiveSubscriptionDisclaimer'
import { OneLinkUrlGenerator, getUserLanguage, isMobile } from 'Lib'
import { mapAppleBadgeImage, mapGoogleBadgeImage, mapOnboardingFinishedImage } from 'Lib/ImageUtils'
import { mapHelpdeskLink } from 'Lib/LinkUtils'
import { Selectors } from 'Reducers'
import { RouteNames } from 'RouteNames'
import Logo from '../Components/Logo'
import { images } from 'images'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaEnvelope } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { getDownloadUrl } from 'Lib/EventsUtils'
import { ApiService } from 'Services'

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const DownloadWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin: ${({ theme }) => theme.spacing.medium};
  max-width: 600px;
`

const Title = styled.h1`
  font-size: ${({ theme }) => theme.fontSizes.large};
  color: ${({ theme }) => theme.colors.text};
  text-align: left;
  align-self: start;
  margin-vertical: ${({ theme }) => theme.spacing.small};
  `

const SubTitle = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  text-align: left;
  color: ${({ theme }) => theme.colors.text};
  margin: 0;
  margin-bottom: ${({ theme }) => theme.spacing.medium};
  width: 100%;
`

const StepsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin: ${({ theme }) => theme.spacing.medium} 0;
  gap: ${({ theme }) => theme.spacing.xLarge};

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: column;
  }
`

const AppImage = styled.img`
  max-width: 40%;
  height: auto;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    max-width: 80%;
  }
`

const Steps = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  gap: ${({ theme }) => theme.spacing.medium};

  p {
    width: 100%;
    text-align: start;
  }
`

const StepWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.small};
`

const StepTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: start;
  width: 100%;
  gap: ${({ theme }) => theme.spacing.small};

  p {
    font-size: ${({ theme }) => theme.fontSizes.medium};
    text-align: start;
    font-weight: bold;
  }
`

const CircleText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colors.primary};
  border-radius: 50%;
  width: ${({ theme }) => theme.spacing.medium};
  height: ${({ theme }) => theme.spacing.medium};
  font-size: ${({ theme }) => theme.fontSizes.small};
  padding: ${({ theme }) => theme.spacing.xsmall};
  color: white;
`

const StepDescription = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  text-align: start;
  color: ${props => props.theme.colors.lightText};
  margin: 0;
  margin-bottom: ${({ theme }) => theme.spacing.medium};
`

const BadgesWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.small};
  margin-top: ${({ theme }) => theme.spacing.small};
  @media (max-width: 300px) { /* When the screen size stops supporting the width of the badges, show them in column*/
  flex-direction: column;
  }
`

const AppleBadge = styled.img<{ enableDownloadLinks: boolean }>`
  cursor: pointer;
  max-height: 40px;
  pointer-events: ${props => props.enableDownloadLinks ? 'auto' : 'none'};
`

const GoogleBadge = styled.img<{ enableDownloadLinks: boolean }>`
  cursor: pointer;
  max-height: 40px;
  pointer-events: ${props => props.enableDownloadLinks ? 'auto' : 'none'};
`

const EmailWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.small};
  margin-top: ${({ theme }) => theme.spacing.small};

  p {
    font-size: ${({ theme }) => theme.fontSizes.medium};
  }

  img {
    width: ${({ theme }) => theme.spacing.medium};
    height: ${({ theme }) => theme.spacing.medium};
  }
`

const Footer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.small};
  margin-top: ${({ theme }) => theme.spacing.small};

  span {
    font-size: ${({ theme }) => theme.fontSizes.medium};
    text-align: center;
    color: ${props => props.theme.colors.lightText};
    margin: 0;
    margin-bottom: ${({ theme }) => theme.spacing.medium};
  }

  a {
    font-size: ${({ theme }) => theme.fontSizes.medium};
    text-align: center;
    color: ${props => props.theme.colors.primary};
    margin: 0;
    margin-bottom: ${({ theme }) => theme.spacing.medium};
    font-style: underline;
  }
`

const LogoWrapper = styled.div`
  width: 100%;
  align-self: start;
  background-color: ${({ theme }) => theme.colors.primary};
  margin-bottom: ${({ theme }) => theme.spacing.medium};
`

const Spacer = styled.div`
  height: ${props => props.theme.spacing.medium};
`

const WEB_APP_ONE_LINK = 'https://bodyfast.onelink.me/EYeT/'

function createEmailBoundOneLink(email: string, originalSearchParams: string, customToken?: string): string {
  const oneLinkGenerator = new OneLinkUrlGenerator({
    oneLinkURL: WEB_APP_ONE_LINK,
    originalURL: originalSearchParams,
    pidKeysList: ['af_pid', 'utm_source'],
    campaignKeysList: ['af_c', 'utm_campaign'],
    gclIdParam: 'af_sub5',
    pidStaticValue: 'webapp_default',
    campaignStaticValue: 'webapp_default'
  })
  oneLinkGenerator.setDeepLinkValue('', 'web_signup')

  // todo: setAfSub1 shall be converted to setAFDeepLinkSub1 once the mobile version including the UDL is released
  oneLinkGenerator.setAfSub1('email', encodeURIComponent(email))

  if (customToken !== undefined) {
    oneLinkGenerator.setAFDeepLinkSub2('customToken', encodeURIComponent(customToken))
  }
  return oneLinkGenerator.generateUrl() ?? WEB_APP_ONE_LINK
}

const Download: React.FC = () => {
  const history = useHistory()
  const hasOldSubscription = history.location.state as { hasOldSubscription?: boolean }

  const { t } = useTranslation()
  const [showQrCode, setShowQrCode] = useState(false)
  const [enableDownloadLinks, setEnableDownloadLinks] = useState(false)
  const lang = getUserLanguage()
  const onboardingFinishedImage = mapOnboardingFinishedImage(lang)
  const googleBadgeImage = mapGoogleBadgeImage(lang)
  const appleBadgeImage = mapAppleBadgeImage(lang)
  const helpdeskLink = mapHelpdeskLink(lang)

  useEffect(() => {
    const isDeviceMobile = isMobile()
    setShowQrCode(!isDeviceMobile)
    setEnableDownloadLinks(isDeviceMobile)
  }, [])

  useEffect(() => {
    return history.listen((_, action) => {
      if (action === 'POP') {
        const downloadUrl = getDownloadUrl()
        history.push(downloadUrl)

        return false
      }
    })
  }, [history])

  const [customToken, setCustomToken] = useState<string | undefined>()

  useEffect(() => {
    async function generateAndSetCustomToken() {
      try {
        const response = await ApiService.generateCustomToken()
        setCustomToken(response.customToken)
        logEvent('web_generateCustomTokenSuccess')
      } catch (error) {
        logEvent('web_generateCustomTokenError', { error })
      }
    }
    void generateAndSetCustomToken()
  }, [])

  const user = useSelector(Selectors.getUser)
  const originalSearchParams = localStorage.getItem('originalSearchParams') ?? ''

  function openApple() {
    logEvent('web_clickedDownloadApp', { linkType: 'apple' })
    window.open(createEmailBoundOneLink(user!.providerData.email!, originalSearchParams, customToken))
  }

  function openAndroid() {
    logEvent('web_clickedDownloadApp', { linkType: 'android' })
    window.open(createEmailBoundOneLink(user!.providerData.email!, originalSearchParams, customToken))
  }

  const title = hasOldSubscription ? t('alreadyPremiumDownload.title') : t('download.title')
  const subtitle = hasOldSubscription ? t('alreadyPremiumDownload.subtitle') : t('download.subtitle')

  return (
    <>
      <Center>
        <DownloadWrapper>
          <LogoWrapper>
            <Logo />
          </LogoWrapper>
          <Title>{title}</Title>
          {hasOldSubscription && <ActiveSubscriptionDisclaimer />}
          <SubTitle>{subtitle}</SubTitle>
          <InfoCard title={t('download.topInfoCardTitle')} content={t('download.topInfoCardContent')} />
          <StepsWrapper>
            <Steps>
              <StepWrapper>
                <StepTitleWrapper>
                  <div>
                    <CircleText>1</CircleText>
                  </div>
                  <div>
                    <p>{t('download.firstStep')}</p>
                  </div>
                </StepTitleWrapper>
                <StepDescription>
                  {t('download.firstStepDescription')}
                </StepDescription>
              </StepWrapper>
              <StepWrapper>
                <StepTitleWrapper>
                  <div>
                    <CircleText>2</CircleText>
                  </div>
                  <div>
                    <p>{t('download.secondStep')}</p>
                  </div>
                </StepTitleWrapper>
                <StepDescription>
                  {t('download.secondStepDescription')}
                </StepDescription>
                <AppImage
                  onClick={openApple}
                  src={onboardingFinishedImage} className="max-h-96 py-4"
                  alt={t('altTexts.onboardingFinished') || ''}
                />
                <InfoCard title={t('download.secondStepInfoCardTitle')} content={t('download.secondStepInfoCardContent')} />
              </StepWrapper>
              <StepWrapper>
                <StepTitleWrapper>
                  <CircleText>3</CircleText>
                  <p>{t('download.thirdStep')}</p>
                </StepTitleWrapper>
                <StepDescription>
                  {t('download.thirdStepDescription')}
                </StepDescription>
              </StepWrapper>
            </Steps>
          </StepsWrapper>
          <Title>{t('download.bottomTitle')}</Title>
          <SubTitle>{t('download.downloadDescriptions')}</SubTitle>
          <BadgesWrapper>
            <AppleBadge
              enableDownloadLinks={enableDownloadLinks}
              onClick={enableDownloadLinks ? openApple : undefined}
              src={appleBadgeImage}
              alt={t('altTexts.appleBadge') || ''}
            />
            <GoogleBadge
              enableDownloadLinks={enableDownloadLinks}
              onClick={enableDownloadLinks ? openAndroid : undefined}
              src={googleBadgeImage}
              alt={t('altTexts.googleBadge') || ''}
            />
          </BadgesWrapper>
          {showQrCode && <>
            <p>{t('download.scanQrCode')}</p>
            <img src={images.onelink} alt={t('altTexts.onelink') || ''} />
          </>}

          <Spacer />
          {!hasOldSubscription && <EmailWrapper>
            <FaEnvelope />
            <p>{t('download.footerEmailDecription')}</p>
          </EmailWrapper>}
        </DownloadWrapper>
      </Center >
      <Footer>
        <span>
          <p>{t('download.footerPart1')} <a href={helpdeskLink} target="_blank" rel="noreferrer">{t('download.footerLink')}</a> {t('download.footerPart2')}</p>
        </span>
      </Footer>
    </>
  )
}

export default Download
